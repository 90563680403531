import { useState } from 'react'
import { BrowserRouter, Routes, Route, Switch } from 'react-router-dom'
import { ConfigProvider, Layout, Space, Row, Col, Affix } from 'antd';

import './App.css';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Home from './components/home'

import Cowork from './components/cowork/cowork';
import CoworkDetail from './components/cowork/detail'

import LivingPlus from './components/livingplus';
import Customization from './components/customization';
import Smart from './components/smart';
import Workpro from './components/workpro';
import Infinite from './components/Infinite';
import Contact from './components/contact';
import Print from './components/print';
import Efficiency from './components/efficiency';
import Values from './components/values';

import ErrorPage from './components/common/error';

function App() {

  const [headerTop, setHeaderTop] = useState(false)

  const [themeColor, SetThemeColor] = useState("#ffffff")
  function changeThemeColor(color) { 

    SetThemeColor(color)
  }

  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#c5af84' } }}>
      <BrowserRouter>
        <div className="App">
          {/* <img className='app_bg' src='https://framerusercontent.com/images/FQjUI6wfedz4QbOWk6kww84rQ8k.png?scale-down-to=2048' /> */}
          <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>


              <Affix onChange={() => { setHeaderTop(!headerTop) }} style={{ borderBottom: "1px solid #eee" }}>
                <Layout.Header
                  className={headerTop ? 'headerTopShadow' : ''}
                  style={{ background: themeColor }}
                >
                  <Header color={changeThemeColor} ></Header>
                </Layout.Header>
              </Affix>
              <Layout.Content style={{ background: themeColor }}>
                {/* <Row gutter={24}>
                  <Col className='header_notice' span={24} style={{ padding: '10px 0', textAlign: 'center', background:'rgb(246 246 246)'}}>
                    我们通过五大方式帮助您的公司节省成本，提升运营效率，为您的公司降本增效。
                    <a href='/efficiency'>点击进一步了解 ></a>
                  </Col>
                </Row> */}
                <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/home" element={<Home />} />
                  
                  <Route path="/cowork" element={<Cowork />} />
                  <Route path="/cowork/detail/:id" element={<CoworkDetail></CoworkDetail>}></Route>

                  <Route path="/livingplus" element={<LivingPlus />} />
                  <Route path="/customization" element={<Customization />} />
                  <Route path="/smart" element={<Smart />} />
                  <Route path="/workpro" element={<Workpro />} />
                  <Route path="/infinite" element={<Infinite />} />
                  <Route path="/contact" element={<Contact />}></Route>
                  <Route path="/print" element={<Print />}></Route>
                  <Route path='/efficiency' element={<Efficiency />}></Route>
                  <Route path='/values' element={<Values />}></Route>

                  <Route path='*' element={<ErrorPage/>}></Route>
           
                </Routes>
              </Layout.Content>
              <Layout.Footer style={{ background: themeColor }}>
                <Footer></Footer>
              </Layout.Footer>
            </Layout>
          </Space>
        </div>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
