import { useState, useEffect } from 'react'
import { Row, Col, Card } from 'antd'
import './styles/efficiency.css';
export default function Values() {


    const vsdata = [
        {
            name: "租期",
            desc: "超灵活",
            others: ["起租需要3～5年", 2, 3, 1],
            bcoss: [2, 6, 8, 4, 1]
        },
        {
            name: "装修",
            desc: "超简单",
            others: [1, 2, 2, 3],
            bcoss: [2, 2, 6, 8]
        },
        {
            name: "服务",
            desc: "超周到",
            others: [1, 2, 3],
            bcoss: [2, 6, 8]
        },
        {
            name: "成本",
            desc: "超级省",
            others: [1, 2, 3],
            bcoss: [2, 6, 8]
        },
        {
            name: "代理服务",
            desc: "超全面",
            others: [1, 2, 3],
            bcoss: [2, 6, 8]
        }
    ]


    return (

        <>


            <Row gutter={24} style={{ marginTop: '50px' }}>
                <Col style={{ textAlign: 'center', fontSize: '25px', marginBottom: '30px' }} span={24}>
                    解决资产管理痛点
                </Col>
            </Row>
            <Row style={{ marginBottom: '50px' }} gutter={24}>
                <Col span={20} offset={2}>
                    <Row gutter={24}>
                        <Col offset={4} className="icon-box" span={6}>
                            <span className="home-icon-1 home-icon"></span>
                            <div>
                                信息化程度低
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-2 home-icon"></span>
                            <div>
                                管理不到位
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-3 home-icon"></span>
                            <div>
                                运营成本高
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }} gutter={24}>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-4 home-icon"></span>
                            <div>
                                营销效率低
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-5 home-icon"></span>
                            <div>
                                风控滞后
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-6 home-icon"></span>
                            <div>
                                决策信息不准确
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-7 home-icon"></span>
                            <div>
                                客户关系疏远
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>



        </>
    )

}