import { useState, useEffect } from 'react'
import { Menu, Row,Col } from "antd";
import { MenuOutlined, PhoneOutlined } from '@ant-design/icons';

import { useNavigate, useLocation,Link } from 'react-router-dom'

import logo from '../../images/logo.png'

export default function MenuHeader(params) {
    const navigate = useNavigate()
    const location = useLocation()
    const [openKey, setOpenKey] = useState('/');

    const [headerColor,setHeaderColor] = useState("#ffffff")

    useEffect(() => { 


        if (location.pathname === "/") {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey("/")
        } else if (location.pathname.indexOf("/cowork") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey('/cowork');
        } else if (location.pathname.indexOf("/apartment") > -1) {
            params.color("#ffffff")
            setHeaderColor("#ffffff")
            setOpenKey('/apartment');
        } else if (location.pathname.indexOf("/customization") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey('/customization');
        } else if (location.pathname.indexOf("/smart") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey('/smart');
        } else if (location.pathname.indexOf("/workpro") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey('/workpro');
        } else if (location.pathname.indexOf("/infinite") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey('/infinite');
        }
     else if (location.pathname.indexOf("/contact") > -1) {
        params.color("#FFFFFF")
        setHeaderColor("#FFFFFF")
            setOpenKey('/contact');
        } else if (location.pathname.indexOf("/print") > -1) {
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey("")
        } else { 
            params.color("#FFFFFF")
            setHeaderColor("#FFFFFF")
            setOpenKey("/")
        }
    },[])
    

    const Items = [
        {
            label: 'BeingCospace :',
            key: '/',
        },
        {
            label: '共享办公',
            key: '/cowork'
        },

        // {
        //     label: "Living Plus",
        //     key: '/livingplus'
        // },
        {
            label: "业务合作",
            key: '/contact'
        },
        // {
        //     label: "定制装修",
        //     key: '/customization'
        // },
        // {
        //     label: "办公智能化",
        //     key: '/smart'
        // },
        // {
        //     label: "WorkPro",
        //     key: '/workpro'
        // },
        // {
        //     label: "Infinite ♾️️",
        //     key: '/infinite'
        // }, 
        // {
        //     label: (<span>了解我们如何为您的公司降本增效</span>),
        //     key: '/efficiency',
        //     //disabled: true
        // },
        // {
        //     label: (<span style={{ color: '#d81e06'  }}><img style={{ width: '25px', position: "relative", top: '5px',left:'-5px' }} src={require('../../images/pinzhi.png')} />品质管理手册</span>),
        //     key: '/values',
        //     //disabled: true
        // },
        // {
        //     label: (<span style={{ color: '#c5af84', fontSize: '20px'}}>4008182666<PhoneOutlined style={{fontSize:'20px',marginLeft:"5px"}}/></span>),
        //     key: '4008182666',
        //     disabled:true
        // }
        

    ]



    function changeMenu(item, key, keyPath, domEvent) {
        window.scrollTo(0,0)
        if (item.key === "/apartment") {
            params.color("#ffffff")
            setHeaderColor("#ffffff")
        } else { 
            params.color("#ffffff")
            setHeaderColor("#FFFFFF")
        }
        

        navigate(item.key)
        setOpenKey(item.key)
    }

    return (
        <>
        <Row gutter={24}>
            <Col xs={12} sm={12} md={12} lg={4} xl={2}>
                <Link to="/">
                    <img className='logo' src={logo} style={{ float: "left" }} />
                </Link>
            </Col>
            <Col xs={12} sm={12} md={12} lg={20} xl={22}>
                <Menu
                    className='headMenu'
                    style={{ background: headerColor }}
                    items={Items}
                    mode="horizontal"
                    onClick={changeMenu}
                    selectedKeys={[openKey]}
                    overflowedIndicator={<MenuOutlined style={{ fontSize: '20px' }} />}
                >

                
                </Menu>
            </Col>
            </Row>
            
        </>
        
    )
}